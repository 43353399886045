.cart-table {
  overflow-x: auto;
  table {
    text-align: left;
    border-collapse: collapse;
    min-width: 650px;
    width: 100%;
    thead {
      font-size: $text-md;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      th {
        padding: 0.5em 0;
      }
    }
    td {
      font-size: $text-md;
      padding: 0.7em 0.5em;
      border: 1px solid rgba(0, 0, 0, 0.15);
      &.price {
        font-weight: 500;
      }
    }
  }
}
