.payment-invoice {
  width: 50%;
  font-size: 0.85rem;
  @include tablet_landscape() {
    width: 70%;
  }
  @include phone {
    width: 90%;
  }

  .buttons-group {
    margin-bottom: 2em;
    .left {
      .print-btn {
        margin-right: 0.5em;
      }
    }
    @include phone() {
      flex-direction: column;
      .right {
        margin-top: 0.5em;
      }
    }
  }
}
